import {
  IMembership,
  IProfileProgramSkill,
  ISkill,
  Program,
} from '@guider-global/shared-types';

export interface GetSkillsParams {
  membership: IMembership;
  program: Program;
}

export function getSkills({
  membership,
  program,
}: GetSkillsParams): IProfileProgramSkill[] {
  const skills = (membership.skills as ISkill[]).map<IProfileProgramSkill>(
    (skill) => {
      const { id, fieldSlug } = skill;
      const option = program.registration?.skills?.options?.find(
        (option) => option.id.current === skill.fieldSlug,
      );
      const label = option?.label ?? 'N/A';

      return {
        id,
        label,
        fieldSlug,
      };
    },
  );
  return skills;
}
